import {
  AUTH_CONFIRM_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SET_STAGE,
} from '../constants';

export function signIn(authenticationData) {
  return async (dispatch, getState, { fetch, history, storage }) => {
    dispatch({
      type: AUTH_LOGIN_REQUEST,
    });

    try {
      const response = await fetch(`/api/authentication`, {
        method: 'POST',
        body: JSON.stringify({ ...authenticationData, strategy: 'local' }),
      });

      if (response.status >= 200 && response.status <= 299) {
        const result = await response.json();

        storage.set('token', result.accessToken, {
          expires: 14,
          path: '/',
        });
        dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: result.user,
        });
        history.push('/');
      } else {
        throw response;
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: AUTH_LOGIN_FAIL,
        payload: err.message,
      });
    }
  };
}

export function signOut() {
  return async (dispatch, getState, { history, storage }) => {
    dispatch({
      type: AUTH_LOGOUT_REQUEST,
    });

    try {
      await storage.remove('token');
      await storage.remove('refresh');
      dispatch({
        type: AUTH_LOGOUT_SUCCESS,
      });
      history.push('/login');
    } catch (e) {
      console.info(e);
    }
  };
}

export function setAuthStage(newStage) {
  return dispatch =>
    dispatch({
      type: AUTH_SET_STAGE,
      payload: newStage,
    });
}

export function forgotPassword(authenticationData) {
  return async (dispatch, getState, { auth }) => {
    dispatch({
      type: AUTH_FORGOT_PASSWORD_REQUEST,
    });

    try {
      if (authenticationData.newPassword) {
        const result = await auth.confirmPassword(authenticationData);
        dispatch({
          type: AUTH_CONFIRM_PASSWORD_SUCCESS,
          payload: result,
        });
      } else {
        const result = await auth.forgotPassword(authenticationData);
        dispatch({
          type: AUTH_FORGOT_PASSWORD_SUCCESS,
          payload: result,
        });
      }
    } catch (e) {
      dispatch({
        type: AUTH_FORGOT_PASSWORD_FAIL,
        payload: e.message,
      });
    }
  };
}

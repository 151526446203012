import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import RCRate from 'rc-rate';
import rc from 'rc-rate/assets/index.css';
import React from 'react';

import InputLabel from '../InputLabel';
import s from './StarRating.css';

const StarRating = ({ onChange, ...rest }) => {
  return (
    <div className={s.root}>
      <InputLabel {...rest} />
      <RCRate
        onChange={val => onChange(val * 20)}
        className={s.rater}
        allowHalf
      />
    </div>
  );
};

StarRating.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default withStyles(rc, s)(StarRating);

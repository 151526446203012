import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  Collapsible,
  Form,
  FormLayout,
  InlineError,
} from '@shopify/polaris';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { email, required } from '../../utils/fieldValidators';
import Input from '../elements/Input';

const ForgotPasswordForm = ({
  authError: error,
  handleSubmit,
  inProgress,
  onCancel,
  stage,
  valid,
}) => (
  <Card sectioned>
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <Field
          label="Email"
          name="Username"
          component={Input}
          validate={[required, email]}
          type="text"
          placeholder="example@email.com"
          disabled={stage === 'confirmPassword'}
        />

        <Collapsible id="verify" open={stage === 'confirmPassword'}>
          <Field
            label="Verification code"
            name="verificationCode"
            helpText="This code has been sent to your e-mail address"
            component={Input}
            type="text"
            validate={[required]}
          />

          <Field
            label="New password"
            name="newPassword"
            component={Input}
            type="password"
            validate={[required]}
          />
        </Collapsible>

        {error && <InlineError fieldID="loginErr" message={error} />}

        {stage === 'confirmPasswordSuccess' ? (
          <Banner
            title="Successfully changed your password!"
            status="success"
            action={{ content: 'Back to login', onAction: onCancel }}
          >
            <p>You are now able to login using your new credentials.</p>
          </Banner>
        ) : (
          <ButtonGroup>
            <Button
              onClick={handleSubmit}
              disabled={!valid}
              disabledMessage={inProgress ? 'Login' : 'Complete all inputs'}
              loading={inProgress}
              type="submit"
            >
              {stage === 'confirmPassword' ? 'Change password' : 'Continue'}
            </Button>
            <Button onClick={onCancel} plain>
              Back to login
            </Button>
          </ButtonGroup>
        )}
      </FormLayout>
    </Form>
  </Card>
);

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  authError: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
  authError: null,
};

export default reduxForm({
  form: 'login',
})(ForgotPasswordForm);

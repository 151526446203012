import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormLayout,
  InlineError,
} from '@shopify/polaris';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { email, required } from '../../utils/fieldValidators';
import Input from '../elements/Input';

const LoginForm = ({
  authError: error,
  handleForgotPassword,
  handleSubmit,
  inProgress,
  valid,
}) => (
  <Card sectioned>
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <Field
          label="Email"
          name="email"
          component={Input}
          validate={[required, email]}
          type="text"
          placeholder="example@email.com"
        />
        <Field
          label="Password"
          name="password"
          component={Input}
          validate={[required]}
          type="password"
        />

        {error && <InlineError fieldID="loginErr" message={error} />}
        <ButtonGroup>
          <Button
            onClick={handleSubmit}
            disabled={!valid}
            disabledMessage={inProgress ? 'Login' : 'Complete all inputs'}
            loading={inProgress}
            type="submit"
          >
            Login
          </Button>
          <Button onClick={handleForgotPassword} plain>
            Forgot password
          </Button>
        </ButtonGroup>
      </FormLayout>
    </Form>
  </Card>
);

LoginForm.propTypes = {
  handleForgotPassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  authError: PropTypes.string,
};

LoginForm.defaultProps = {
  authError: null,
};

export default reduxForm({
  form: 'login',
})(LoginForm);

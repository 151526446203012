// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qDdB {\n  display: flex;\n  justify-content: space-between;\n  list-style: none;\n  margin: 0 0 0.35em;\n  padding: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"labels": "_2qDdB"
};
module.exports = exports;

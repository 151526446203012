import { Checkbox, RangeSlider, Select, TextField } from '@shopify/polaris';
import React from 'react';

import Slider from './Slider';
import StarRating from './StarRating';

const Input = field => (
  <>
    {field.type === 'select' && (
      <>
        <Select
          {...field}
          {...field.input}
          label={field.meta.label}
          error={field.meta.submitFailed && field.meta.error}
        />
      </>
    )}
    {field.type === 'range' && (
      <RangeSlider
        {...field}
        {...field.input}
        label={field.meta.label}
        error={field.meta.submitFailed && field.meta.error}
      />
    )}
    {field.type === 'likert' && (
      <Slider
        {...field}
        {...field.input}
        {...field.meta}
        inputOptions={field.options}
        value={
          field.meta.defaultValue
            ? field.meta.defaultValue
            : Number(field.value)
        }
        error={field.meta.submitFailed && field.meta.error}
      />
    )}
    {field.type === 'preference' && (
      <Slider
        {...field}
        {...field.input}
        {...field.meta}
        inputOptions={field.options}
        value={
          field.meta.defaultValue
            ? field.meta.defaultValue
            : Number(field.value)
        }
        error={field.meta.submitFailed && field.meta.error}
      />
    )}
    {field.type === 'starRating' && (
      <StarRating
        {...field}
        {...field.input}
        {...field.meta}
        inputOptions={field.options}
        value={Number(field.value)}
        error={field.meta.submitFailed && field.meta.error}
      />
    )}
    {field.type === 'checkbox' && (
      <Checkbox
        {...field}
        {...field.input}
        {...field.meta}
        checked={field.input.value}
        error={field.meta.submitFailed && field.meta.error}
      />
    )}
    {['text', 'password', 'number'].includes(field.type) && (
      <TextField
        {...field}
        {...field.input}
        value={String(field.input.value)}
        error={field.meta.submitFailed && field.meta.error}
        label={field.meta.label}
      />
    )}
  </>
);

export default Input;

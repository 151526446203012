import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import RCSlider from 'rc-slider';
import rc from 'rc-slider/assets/index.css';
import React from 'react';

import InputLabel from '../InputLabel';
import s from './Slider.css';

const Slider = ({ label, max, min, name, onChange, options, value }) => {
  const inputOptions = options || [
    { id: 1, label: 'Not at all' },
    { id: 2, label: 'Very much' },
  ];
  return (
    <>
      <InputLabel
        label={label}
        labelHidden={!!options}
        name={name}
        options={inputOptions}
      />
      <RCSlider
        // eslint-disable-next-line css-modules/no-undef-class
        className={s.slider}
        onAfterChange={onChange}
        min={typeof min === 'number' ? min : 0}
        max={typeof max === 'number' ? max : 100}
        defaultValue={typeof value === 'number' ? value : 50}
        name={name}
        id={name}
      />
    </>
  );
};

Slider.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.number,
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  value: 50,
  options: null,
};

export default withStyles(rc, s)(Slider);

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ooI7 ._16jdg {\n  font-size: 3em;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_3ooI7",
	"rater": "_16jdg"
};
module.exports = exports;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".rc-slider._3Nb3F {\n  --handleSize: 28px;\n\n  margin: 0 auto;\n  padding: 12px 0 17px 0;\n  width: calc(100% - var(--handleSize));\n}\n\n._3Nb3F .rc-slider-rail {\n  background-color: #dfe3e8;\n  border-radius: 0;\n  height: 6px;\n}\n\n._3Nb3F .rc-slider-track {\n  background-color: #dfe3e8;\n  height: 6px;\n}\n\n._3Nb3F .rc-slider-handle {\n  background-color: #fff;\n  border: 1px solid black;\n  height: var(--handleSize);\n  margin-left: calc((var(--handleSize) / 2) * -1);\n  margin-top: -11px;\n  width: var(--handleSize);\n}\n\n._3Nb3F .rc-slider-handle:hover,\n._3Nb3F .rc-slider-handle:active {\n  border-color: #333;\n  box-shadow: 0 0 5px #333;\n}\n\n._3Nb3F .rc-slider-handle:focus {\n  border-color: #333;\n}\n\n._3Nb3F .rc-slider-handle:active {\n  background-color: #919eab;\n}\n", ""]);
// Exports
exports.locals = {
	"slider": "_3Nb3F"
};
module.exports = exports;

import { AppProvider, FooterHelp, Layout, Link, Page } from '@shopify/polaris';
import polaris from '@shopify/polaris/styles.css';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginActions from '../../actions/auth.action';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import LoginForm from '../forms/LoginForm';

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...loginActions }, dispatch);

@withStyles(polaris)
@connect(mapStateToProps, mapDispatchToProps)
class Login extends PureComponent {
  static propTypes = {
    auth: PropTypes.shape().isRequired,
    forgotPassword: PropTypes.func.isRequired,
    setAuthStage: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
  };

  render() {
    const { auth, forgotPassword, setAuthStage, signIn } = this.props;

    return (
      <AppProvider>
        <Page title="Login">
          <Layout>
            <Layout.AnnotatedSection
              description="Please login using your credentials."
              title="Please login"
            >
              {auth.stage === 'login' && (
                <LoginForm
                  authError={auth.error.message}
                  onSubmit={signIn}
                  inProgress={auth.isSigningIn}
                  handleForgotPassword={() => setAuthStage('forgotPassword')}
                />
              )}
              {auth.stage.includes('Password') && (
                <ForgotPasswordForm
                  stage={auth.stage}
                  authError={auth.error.message}
                  onSubmit={forgotPassword}
                  onCancel={() => setAuthStage('login')}
                  inProgress={auth.isRequestingForgotPassword}
                />
              )}
            </Layout.AnnotatedSection>

            <Layout.Section>
              <FooterHelp>
                Need help? Mail us on
                <Link url="mailto:developer@scentronix.com">
                  developer@scentronix.com
                </Link>
                .
              </FooterHelp>
            </Layout.Section>
          </Layout>
        </Page>
      </AppProvider>
    );
  }
}

export default Login;

import { DisplayText } from '@shopify/polaris';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import s from './InputLabel.css';

const InputLabel = ({ label, labelHidden, name, options }) => (
  <label htmlFor={name}>
    {!labelHidden && <DisplayText size="small">{label}</DisplayText>}
    {options && options.length && (
      <ul className={s.labels}>
        {options.map(({ id, label: subLabel }) => (
          <li key={id}>{subLabel}</li>
        ))}
      </ul>
    )}
  </label>
);

InputLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

InputLabel.defaultProps = {
  label: '',
  labelHidden: false,
  options: null,
};

export default withStyles(s)(InputLabel);

import React from 'react';

import Login from '../../components/templates/Login';

async function action() {
  return {
    title: 'Login',
    chunks: ['auth'],
    component: <Login />,
  };
}

export default action;
